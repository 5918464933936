angular.module('eOpti.app.roles').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.roles', {
            url: '/roles',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.roles.list', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/roles/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    defaults: 'usersList'
                }, {
                    functions: 'roles',
                    args: [null]
                }]
            }]
        })
        .state('app.role', {
            url: '/role',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.role.add', {
            url: '/add',
            template: '<opti-role-add></opti-role-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    defaults: 'usersList'
                }, {
                    functions: 'roles',
                    args: ['state']
                }, {
                    name: 'role.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.role.edit', {
            url: '/:id/edit',
            template: '<opti-role-edit></opti-role-edit>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    defaults: 'usersList'
                }, {
                    functions: 'roles',
                    args: ['state']
                }, {
                    functions: 'role',
                    args: [$state.params.id]
                }, {
                    name: 'role.breadcrumb.EDIT'
                }]
            }]
        })
        .state('app.role.assign', {
            url: '/:id/assign',
            template: '<opti-role-assign></opti-role-assign>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    defaults: 'usersList'
                }, {
                    functions: 'roles',
                    args: ['state']
                }, {
                    functions: 'role',
                    args: [$state.params.id]
                }, {
                    name: 'role.breadcrumb.ASSIGN'
                }]
            }]
        })
        .state('app.roles.exceptions', {
            url: '-exceptions/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/roles/exceptions.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    defaults: 'usersList'
                }, {
                    functions: 'roleExceptions',
                    args: [null]
                }]
            }]
        })
        .state('app.role.exceptionRemove', {
            url: '-exception/remove/:id',
            template: '<opti-dimmer v-props-active="true"></opti-dimmer>',
            controller: ['$http', '$state', '$window', function($http, $state, $window) {
                $http.get('api/roles/exceptions/remove/' + $state.params.id).then(() => {
                    $window.history.back()
                })
            }]
        })
        .state('app.role.exceptionAdd', {
            url: '-exception/add',
            template: '<opti-role-exception-add></opti-role-exception-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    defaults: 'usersList'
                }, {
                    functions: 'roleExceptions',
                    args: ['state']
                }, {
                    name: 'role.breadcrumb.ADD'
                }]
            }]
        })
}])